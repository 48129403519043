import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    layout: {
        maxWidth: '1280px',
        margin: '0 auto'
    }
}));

export default useStyles;
